import { Dialog, DialogTitle, Stack, TextField, DialogContent, 
  Box, DialogActions, FormControl, InputLabel, MenuItem, Select, Typography } 
  from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import * as React from "react";
import * as ATCAConstants from '../util/ATCAConstants'
import * as Utils from '../util/Utils'
import {ProjectContext} from "../schedule/ProjectContext";

const DEFAULT_IMPORT_SETTING = {
  duration: 300,
  scanType: 'Normal',
  pointing: 'Global',
  correlator_configuration: ''
}

export default function ImportSourceDialog(
  props: {
    open: boolean,
    targets: string,
    handleImport: (targets: string[], defaultSetting: any) => void,
    handleCancel: () => void,
  }
) {
  const projectContext = React.useContext(ProjectContext);

  const [targets, setTargets] = React.useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number[]>([]);
  const [defaultImportSetting, setDefaultImportSetting] = React.useState<any>(DEFAULT_IMPORT_SETTING);

  React.useEffect(() => {
    if (props.open) {
      // convert string to targets
      // get rid of non-ascii characters
      const targets = Utils.getTargets(props.targets as string);
      setTargets(targets);
      setSelectedIndex([]);
    }
  }, [props.open, props.targets]);

  const getSelectedTargets = () => {
    const selectedTargets: any[] = [];
    for (const s of selectedIndex) {
      const selectedTarget = targets[s];
      selectedTargets.push(selectedTarget);
    }

    return selectedTargets;
  }

  const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selectAll = [...Array(targets.length)].map((_, i) => i);
      setSelectedIndex(selectAll);
    } else {
      setSelectedIndex([]);
    }
  };

  const toggleSelection = (index: number) => {
    let newSelections = [...selectedIndex];
    const i = selectedIndex.indexOf(index);
    if (i < 0) {
      newSelections.push(index);
    } else {
      newSelections.splice(i, 1);
    }

    setSelectedIndex(newSelections.sort((n1, n2) => n1 - n2));
  }

  const setDefaultValue = (fieldName: string, value: any) => {
    setDefaultImportSetting({
      ...defaultImportSetting,
      [fieldName]: value
    })
  }

  function renderCorrelatorConfig(config: any): React.ReactNode {
    const setting = projectContext.getCorrelatorSetting(config['correlator_setting']);
    const freqConfig = setting['frequency_configuration'] || {};

    return (<Stack direction="row" spacing={2}>
      <Typography variant="body2">
        {freqConfig['receiver']}
      </Typography>
      <Typography variant="body2">
        {(setting['spectral_points_per_subband']
          * setting['subbands_per_IF']
          * ATCAConstants.NUMBER_OF_IF)} points
      </Typography>

      <Typography variant="body2">
        {(config['sub_band_configuration'] || []).length} zooms
      </Typography>

      <Typography variant="body2">
        {projectContext.calculateDataRate(config['name'])} kB/s
      </Typography>
    </Stack>);
  }

  return (
    <Dialog open={props.open} PaperProps={{ sx: {height: '70%'} }}
      maxWidth="md" fullWidth>
      <DialogTitle id="file-browser-dialog-title">
        Add Sources from Catalogue
      </DialogTitle>
      <DialogContent>
        <Checkbox
          onChange={toggleSelectAll}
          checked={selectedIndex.length === targets.length && targets.length !== 0}
          indeterminate={
            selectedIndex.length !== targets.length && selectedIndex.length !== 0
          }
          disabled={targets.length === 0}
          inputProps={{
            'aria-label': 'all items selected',
          }}
          sx={{marginLeft: '10px'}}
        />
        <Stack direction={'row'} height={'calc(100% - 50px)'} spacing= {4}>
          <Box sx={{
            height: '100%',
            width: '50%', border: '1px solid #eeeeee',
          }}>
            <List sx={{
              padding: 0, height: '100%', overflowY: 'scroll'
            }}>
              {targets.map((target: any, index) => (
                <ListItem key={`target-${index}`}
                  role="listitem"
                  onClick={e => toggleSelection(index)}
                  sx={{
                    padding: '10px',
                    borderBottom: '1px solid #eeeeee',
                    bgcolor: (selectedIndex.indexOf(index) >= 0) ? '#e0f7fa' : '',
                    "&:hover": {
                      cursor: 'pointer',
                      bgcolor: '#f5f5f5',
                    }
                  }}>
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedIndex.indexOf(index) >= 0}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={target['name']}
                    secondary={
                      <Stack>
                        <Typography>
                          {`${target['ra']} ${target['ra']} ${target['coord_sys']||'J2000'}`}
                        </Typography>
                        <Typography>
                          {target['velocity']}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Stack spacing={4} flexGrow={4}>
            <TextField id="default-duration" label="Default Scan Duration (seconds)"
              value={defaultImportSetting['duration'] || 0}
              onChange={(e) => setDefaultValue('duration', Number(e.target.value))}
              variant="standard" />
            <FormControl fullWidth>
              <InputLabel id="default-scan-type-label">Default Scan Type</InputLabel>
              <Select
                labelId="default-scan-type-label"
                id="default-scan-type"
                label="Default Scan Type"
                displayEmpty={true}
                onChange={e => setDefaultValue('scanType', e.target.value as string)}
                value={defaultImportSetting['scanType'] || ''}
              >
                {
                  ATCAConstants.SCAN_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="default-pointing-label">Default Pointing</InputLabel>
              <Select
                labelId="default-pointing-label"
                id="default-pointing"
                label="Default Pointing"
                displayEmpty={true}
                onChange={e => setDefaultValue('pointing', e.target.value as string)}
                value={defaultImportSetting['pointing'] || ''}
              >
                {
                  ATCAConstants.POINTINGS.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="default-corr-config-label">Default Correlator Configuration</InputLabel>
              <Select
                labelId="default-corr-config-label"
                id="default-corr-config"
                label="Default Correlator Configuration"
                displayEmpty={true}
                renderValue={(selected) => selected}
                onChange={e => setDefaultValue('correlator_configuration', e.target.value as string)}
                value={defaultImportSetting['correlator_configuration'] || ''}
              >
                {
                  projectContext.correlatorConfigurations.map((config, index) => (
                    <MenuItem key={config['name'] + '-' + index} value={config['name']}>
                      <ListItemText primary={config['name']}
                        secondary={renderCorrelatorConfig(config)}
                      />
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus
          disabled={selectedIndex.length === 0}
          onClick={e => props.handleImport(getSelectedTargets(), defaultImportSetting)}
        >
          Add
        </Button>
        <Button onClick={props.handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
