import { Stack, Button, Dialog, Typography, DialogContent } from '@mui/material';
import * as React from 'react';
import TargetEditor from './TargetEditor';

const INIT_TARGET = {
  name: '',
  coord_sys: 'J2000',
  ra: '',
  dec: '',
  velocity: '',
  frame: 'lsr',
  convention: 'radio'
};

export default function NewTargetDialog(
  props: {
    addTarget: (scan: any) => void,
    message: string,
    open: boolean,
    handleClose: () => void
}) {

  const [target, setTarget] = React.useState<any>(INIT_TARGET);

  const addTarget = () => {
    if (!target['name']) {
      if (target['coord_sys']||'J2000' === 'J2000') {
        const ra = target['ra']||'';
        const dec = target['dec']||'';
        // generate a name
        let name = 'J';
        const ras = ra.split(':');
        const decs = dec.split(':');

        if ((ras.length>2) && (decs.length>2)) {
          name += ras[0] + ras[1];
          if (decs[0].startsWith('-') || decs[0].startsWith('+')) {
            name += decs[0];
          } else {
            name += '+' + decs[0];
          }

          name += decs[1];
          target['name'] = name;
        }
      }
    }

    props.addTarget(target);
  }

  React.useEffect(() => {
    if (!props.open) {
      setTarget(INIT_TARGET);
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth
      onClose={props.handleClose}>
      <DialogContent>
        <Stack spacing={2}>
          <TargetEditor target={target} setTarget={setTarget}/>
            <Button variant="outlined" onClick={addTarget}>
              Add
            </Button>
            <Typography variant="caption" >
              {props.message}
            </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}